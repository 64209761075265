<template>
  <b-card title="Description"   @shown="getData(blogData)"> 
    <div>
     

        <!-- Row Loop -->
        <template   v-for="(item, index) in BlogInputRepeater"
          
         >
        <b-row
        :id="item.id"
         :key="'content-'+index"
          ref="row"
          v-if="item.remove !== true"

        >
          <b-col md="2" class="mb-2"></b-col>
          <b-col md="8" class="text-right mb-2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col md="2" class="mb-2"></b-col>
          <b-col md="2">  
            <b-img
                v-if="item.layout_id == 1"
                :src="require('@/assets/images/blog/left.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />
              <b-img
                v-else-if="item.layout_id == 2"
                :src="require('@/assets/images/blog/right.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />
              <b-img
                v-else-if="item.layout_id == 3"
                :src="require('@/assets/images/blog/middle.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />

          </b-col>
          <b-col 
          md="8"
        
            
          >
          <b-form-group
                label="Layout"
                label-for="layout"
              >
            <v-select
           
                    v-model="item.layout_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="(option) => option.id"
                    :options="Layouts"
            />
            </b-form-group>
            <b-form-group
                label="Title"
                label-for="title"
              >
              <b-form-input
               
                 v-model="item.title"
                 autofocus
                
                 trim
                 placeholder="Title"
               
               />
        
                
              </b-form-group>
           <!-- <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-info"
              class="mt-2"
              @click="item.show = !item.show"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-25"
                v-if="!item.show"
              />
              <feather-icon
              v-else
                icon="EyeOffIcon"
                class="mr-25"
              />
              
             
            </b-button>-->
          </b-col>
          <b-col
            
            md="2"
           
          >
            
          </b-col>
          <b-col  md="2" class="mt-2" >
            
          </b-col>
          <b-col  md="8"  class="mt-2" >
            
            <b-form-group
                label="Image"
                label-for="image"
              >
              <b-card-text v-if="item.image" class="my-1">
                <b-img
                
                  :src="item.fullpath"
                 
                  width="100"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" v-if="item.image" @click="revomeImage(index)" >
                  <feather-icon
                    icon="XCircleIcon"
                   
                    class="text-danger"
                
                  />
                  Remove  
                  
                </b-button>
              </b-card-text>
              <b-form-file
              
             
              v-model="item.contentFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
             
              <b-card-text class="my-1">
                Selected file: <strong>{{ item.contentFile ? item.contentFile.name : '' }}</strong>
              </b-card-text>
        
                
              </b-form-group>
            
              <b-form-group
                label="Description"
                label-for="description"
              >
              <quill-editor :options="snowOption" v-model="item.description" />
                
              </b-form-group>
              
          </b-col>
          <b-col  md="2"  class="mt-2" >
            
          </b-col>


          <!-- Remove Button -->
          
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </template>
      
    </div>
    <b-row>
      <b-col md="2"></b-col>
      <b-col md="8"> <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button></b-col>
      <b-col md="2"></b-col>
    </b-row>
   

    
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCard, BCardText, BFormFile, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'



import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
//import { slugify } from '@/@fake-db/utils'

import { quillEditor } from 'vue-quill-editor'


export default {
  components: {
    BCard,
    BCardText, BFormFile,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    
    vSelect,
    quillEditor
  },
  
  directives: {
    Ripple,
  },
  model: {
     prop: 'BlogInputRepeater',
     prop: 'BlogInputRepeaterRemove',
     event: 'update:blog-input-repeater',
     event: 'update::blog-input-repeater-remove'
   },
   props: {
    BlogInputRepeater: {
       type: Array,
       required: true,
     },
     BlogInputRepeaterRemove: {
       type: Array,
       required: true,
     },
     
     blogData: {
       type: Object,
       required: false,
     },
   },
  mixins: [heightTransition],
  data(){
      return {
        snowOption: {
          theme: 'snow',
        },
      }
   },
  mounted() {
    //this.initTrHeight()
   
  },
  created() {
  
   // window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
   // window.removeEventListener('resize', this.initTrHeight)
  },
  setup(props, { emit }) {
    const Layouts = ref([
      {
        id:1,
        title:'Layout 1'
      },
      {
        id:2,
        title:'Layout 2'
      },
      {
        id:3,
        title:'Layout 3'
      },
    ]);
    
    //const BlogInputRepeater = ref(props.BlogInputRepeater);
    const nextTodoId = ref(props.BlogInputRepeater.length+1);
    //console.log('nextTodoId',nextTodoId.value);
    //console.log('BlogInputRepeater',props.BlogInputRepeater);
    const repeateAgain = ()=>{
      props.BlogInputRepeater.push({
          id:null,
          layout_id: 1,
          image:null,
          title:'',
          description:'',
          show:true
          //id: nextTodoId.value += nextTodoId.value,
      });
      //emit('update:blog-input-repeater', BlogInputRepeater.value);
    } 
    const revomeImage = (key)=>{
      //console.log(key, BlogInputRepeater.value  );
        console.log(key,  props.BlogInputRepeater  );
        props.BlogInputRepeater[key].image = null;
        //BlogInputRepeater.value[key]['image'] = null;
     }
    const removeItem = (index)=>{
     
      
      if( props.BlogInputRepeater[index].id > 0){
        props.BlogInputRepeaterRemove.push(props.BlogInputRepeater[index].id);
      }
      props.BlogInputRepeater.splice(index,1);
     /* if( props.BlogInputRepeater[index].id > 0){
        props.BlogInputRepeater[index].remove = true;
      }else{
        props.BlogInputRepeater.splice(index,1);
      }*/
     
     // emit('update:blog-input-repeater', BlogInputRepeater.value);
    }
   

    return {
      repeateAgain,
      nextTodoId,
      Layouts,
      removeItem,
      revomeImage,
    
    }
  },
  methods: {
    
    
   /* initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
       // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },*/
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
